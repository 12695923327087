import breakpoints from 'constants/breakpoints';

const theme = {
  gutter: {
    desktop: '4rem',
    mobile: '2rem',
  },
  accent: '#f90000',
  background: '#f9f4f4',
  backgroundDark: '#f9e8e8',
};

export default theme;
