import React from 'react';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container } from './header.css';
import Nav from 'components/header/nav';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
        logo: file(relativePath: {eq: "images/logo/thirdslant_lockup-red.png"}) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <AnimatedContainer>
      <Container>
        <Link
          activeStyle={{visibility: 'hidden'}}
          to="/"
        >
          <Img fixed={data.logo.childImageSharp.fixed} />
        </Link>
        <Nav />
      </Container>
    </AnimatedContainer>)
  };

export default Header;
