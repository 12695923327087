// derived from colors from Colorbox
// https://www.colorbox.io/#steps=11#hue_start=359#hue_end=301#hue_curve=easeInQuad#sat_start=4#sat_end=90#sat_curve=easeOutQuad#sat_rate=130#lum_start=100#lum_end=53#lum_curve=easeOutQuad#lock_hex=ef2426#minor_steps_map=0

module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#c80057',

  primary: '#ef2426',
  primaryLight: '#f6504d',
  primaryDark: '#e50925',

  secondary: '#9e007f',
  secondaryLight: '#b4006f',
  secondaryDark: '#8780085',

  black: '#080808',
};
